<template>
  <div class="scoring-form">
    <div class="header">
      <div class="total-score">
        综合评分：
        <el-tag type="success" size="medium" style="font-size: 18px"
          >{{ scores.totalScore || 0 }} 分</el-tag
        >
      </div>
      <!-- <el-button type="primary" @click="test">测试</el-button> -->
    </div>
    <el-form ref="scoreForm" :model="scores">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="label" label="评分项" width="180" align="center">
          <template slot-scope="scope">
            <div class="label-cell">
              {{ scope.row.label }}
              <el-tooltip
                v-if="canEditScore(scope.row.role) && scope.row.warn"
                class="item"
                effect="dark"
                :content="scope.row.warn"
                placement="top"
              >
                <i class="el-icon-question warning-icon"></i>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="评分人" width="180" align="center">
          <template slot-scope="scope">
            <div class="label-cell">
              {{ scores[scope.row.name] }}
            </div>
          </template>
        </el-table-column>

        <el-table-column label="评分" width="220" align="center">
          <template slot-scope="scope">
            <el-form-item
              :prop="scope.row.field"
              :rules="getValidationRules(scope.row.role, false)"
              class="score-form-item"
            >
              <el-input
                v-model="scores[scope.row.field]"
                :disabled="!canEditScore(scope.row.role)"
                :placeholder="
                  !canEditScore(scope.row.role)
                    ? scores[scope.row.isScore]
                    : '请输入评分'
                "
                class="score-input"
              >
                <template slot="append">分</template>
              </el-input>
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column label="评分说明" align="center">
          <template slot-scope="scope">
            <el-form-item
              v-if="showRemarkInput(scope.row.field)"
              :prop="scope.row.remark"
              :rules="getValidationRules(scope.row.role, scope.row.field)"
              class="remark-form-item"
            >
              <el-input
                v-model="scores[scope.row.remark]"
                type="textarea"
                :disabled="!canEditScore(scope.row.role)"
                :rows="1"
                :autosize="{ minRows: 1, maxRows: 3 }"
                placeholder="请输入说明理由"
                class="remark-input"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    // 接收项目类型参数
    projectType: {
      type: String,
      default: "normal",
    },
    userRoles: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      loading: false,
      scores: {
        chairmanScore: null,
        managerScore: null,
        departmentScore: null,
        businessScore: null,
        qualityScore: null,
        archiveScore: null,
        totalScore: null,
        // 添加备注字段
        managerRemark: null,
        departmentRemark: null,
        businessRemark: null,
        qualityRemark: null,
        archiveRemark: null,
      },
      // userRoles: [], // 存储用户角色
      chairmaForm: {
        field: "chairmanScore",
        label: "董事长",
        role: "CHAIRMAN_ROLE",
        remark: "chairmanRemark",
        isScore: "isChairmanScore",
        name: "chairmanName",
      },
      scoresForm: [
        {
          field: "managerScore",
          label: "分管高管",
          role: "MANAGER_ROLE",
          remark: "managerRemark",
          isScore: "isManagerScore",
          name: "managerName",
          needRemark: {
            min: 0.9,
            max: 1,
          },
          warn: "评分一般在0.70至1.10之间，如果低于0.90或高于1应说明具体理由",
        },
        {
          field: "departmentScore",
          label: "部门经理",
          role: "DEPARTMENT_ROLE",
          remark: "departmentRemark",
          isScore: "isDepartmentScore",
          name: "departmentName",
          needRemark: {
            min: 0.9,
            max: 1,
          },
          warn: "评分一般在0.70至1.10之间，如果低于0.90或高于1应说明具体理由",
        },
        {
          field: "businessScore",
          label: "商务负责人",
          role: "BUSINESS_ROLE",
          remark: "businessRemark",
          isScore: "isBusinessScore",
          name: "businessName",
          needRemark: {
            min: 0.9,
            max: 1,
          },
          warn: "评分一般在0.70至1.10之间，如果低于0.90或高于1应说明具体理由",
        },
        {
          field: "qualityScore",
          label: "质检负责人",
          role: "QUALITY_ROLE",
          remark: "qualityRemark",
          isScore: "isQualityScore",
          name: "qualityInspectorName",
          needRemark: {
            min: 0.9,
          },
          warn: "评分一般在0.60至1.00之间，如果低于0.90应说明具体理由",
        },
        {
          field: "archiveScore",
          label: "成果归档",
          role: "ARCHIVE_ROLE",
          remark: "archiveRemark",
          isScore: "isArchiveScore",
          name: "archiveName",
          needRemark: {
            min: 0.8,
          },
          warn: "评分则在0.00至1之间，如果低于0.80应说明具体理由",
        },
      ],
      ruleForm: {},
    };
  },

  watch: {
    options: {
      immediate: true,
      deep: true,
      handler: function (newVal) {
        if (newVal?.id) {
          // this.$api.project
          //   .getProjectScoreRole(newVal.id)
          //   .then((res) => {
          //     this.userRoles = res?.data;
          //   })
          //   .catch((err) => {
          //     console.log("err", err);
          //   });
        }
      },
    },
    projectScoreResponse: {
      immediate: true,
      deep: true,
      handler: function (newVal) {
        this.scores = newVal;
        this.$refs.scoreForm?.clearValidate();
      },
    },
  },
  computed: {
    ...mapState({
      projectScoreResponse: (state) => state.project.projectScoreResponse,
    }),

    tableData() {
      if (this.projectType == "important") {
        return [
          {
            ...this.chairmaForm,
          },
          ...this.scoresForm,
        ];
      } else {
        return [...this.scoresForm];
      }
    },
  },

  methods: {
    test() {
      console.log("projectScoreResponse", this.scores);
    },
    getValidationRules(role, field) {
      if (this.canEditScore(role)) {
        if (field && this.showRemarkInput(field)) {
          return [
            {
              required: true,
              message: "请输入评分说明",
              trigger: ["blur", "change"],
            },
          ];
        } else {
          return [
            {
              required: true,
              message: "请输入评分",
              trigger: ["blur", "change"],
            },
            {
              pattern: /^\d+(\.\d{1,2})?$/,
              message: "评分必须为数值",
              trigger: ["blur", "change"],
            },
            {
              validator: (rule, value, callback) => {
                const numericValue = parseFloat(value);

                if (numericValue < 0 || numericValue > 2) {
                  callback(new Error("评分必须在0到2之间"));
                } else {
                  callback();
                }
              },
              trigger: ["blur", "change"],
            },
          ];
        }
      }
      return [];
    },
    // 判断当前用户是否有权限编辑特定评分项
    canEditScore(role, field) {
      if (role) {
        return this.userRoles.some((userRole) => role === userRole);
      } else {
        // 通过field判断获取role来判断
        const role = this.scoresForm.find((item) => item.field === field)?.role;
        return this.userRoles.some((userRole) => role === userRole);
      }
    },
    // 显示备注输入框的条件
    showRemarkInput(field) {
      if (!this.scores[field]) return false;
      const range = this.scoresForm.find(
        (item) => item.field === field
      )?.needRemark;
      if (!range) return false;
      const score = parseFloat(this.scores[field]);
      return score < range.min || score > range.max;
    },
    // 提交评分 --在Edit组件中调用
    submitScore() {
      this.$refs.scoreForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          // 如果对应的评分不超过范围，对应的评分说明给个空字符串
          this.scoresForm.map((item) => {
            if (!this.showRemarkInput(item.field)) {
              this.scores[item.remark] = "";
            }
          });
          // 只要那些能够编辑的评分以及评分说明
          const editScoresItems = this.scoresForm.filter((item) =>
            this.canEditScore(item.role)
          );
          let editScores = {};
          editScoresItems.forEach((item) => {
            editScores[item.field] = this.scores[item.field];
            editScores[item.remark] = this.scores[item.remark];
          });

          const data = {
            projectId: this.options.id,
            ...editScores,
          };
          // 提交逻辑
          if (this.projectScoreResponse?.scoreId) {
            data.scoreId = this.projectScoreResponse?.scoreId;
            this.$api.project
              .updateProjectScore(data)
              .then((res) => {
                this.$message.success("评分提交成功");
              })
              .catch((err) => {
                console.log("err", err);
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            this.$api.project
              .addProjectScore(data)
              .then((res) => {
                this.$message.success("评分提交成功");
              })
              .catch((err) => {
                console.log("err", err);
              })
              .finally(() => {
                this.loading = false;
              });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.label-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.warning-icon {
  color: #e6a23c;
  margin-left: 5px;
}

.score-form-item {
  margin-bottom: 0;
}

.score-input {
  width: 160px;
}

.remark-form-item {
  margin-bottom: 0;
}

.remark-input {
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 0 20px;

  .total-score {
    font-size: 18px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

/deep/ {
  .el-table td {
    padding: 10px;
    .cell {
      overflow: visible !important;
    }
  }

  .el-form-item__error {
    position: absolute;
    top: 100%;
    left: 0;
  }

  .el-input.is-disabled .el-input__inner {
    background-color: #e7e9ee !important;
    border-color: #d5d8df;
    cursor: not-allowed;
  }

  .el-input.is-disabled .el-input-group__append {
    background-color: #e4e7ed;
    border-color: #e4e7ed;
    color: #909399;
  }
  .el-textarea.is-disabled .el-textarea__inner {
    background-color: #e7e9ee !important;
    border-color: #d5d8df;
    cursor: not-allowed;
  }
}
</style>
